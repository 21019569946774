.report-table-container {
  padding: 1rem;
  align-self: center;

  .report-table-heading {
    text-align: center;
    font-weight: 500;
    font-size: 20px;
    color: #4b0082;
    font-family: OpenSans-Regular;
  }

  .report-table-header {
    text-align: center;
    background: #36304a;
    font-family: OpenSans-Regular;
    color: #fff;
    height: 60px;

    th {
      // line-height: ;
      width: 260px;
    }
  }
}
