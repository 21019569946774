.sign-in-form-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 10px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px;

  .app-title {
    color: #4b0082;
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 35px;
    font-family: "Times New Roman", Times, serif;
  }

  .logo-app {
    width: 100px;
    height: 100px;
    margin-bottom: 20px;
  }

  .form-title {
    font-size: 24px;
    margin-bottom: 35px;
    color: #4b0082;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .form-input-container {
      margin-bottom: 20px;
      position: relative;

      .form-input-label {
        font-size: 16px;
        color: #4b0082;
        position: absolute;
        top: -14px;
        left: 0;
        transition: all 0.2s ease-in-out;
        pointer-events: none;
        background-color: white;
        padding: 0 5px;
      }

      .form-input {
        width: 100%;
        height: 50px;
        border: 1px solid #4b0082;
        border-radius: 5px;
        padding: 10px;
        font-size: 16px;
        transition: all 0.2s ease-in-out;

        &:focus {
          border-color: #4b0082;
          outline: none;
        }
      }
    }

    .error-message-container {
      display: inline;
      color: #ff0000;
      font-size: 14px;
      margin-top: 0px;
      align-self: flex-end;
    }

    .sign-in-button {
      background-color: #4b0082;
      color: white;
      padding: 10px 20px;
      border-radius: 5px;
      border: none;
      margin-top: 20px;
      transition: all 0.2s ease-in-out;

      &:hover {
        cursor: pointer;
        transform: scale(1.1);
      }
    }
  }
}
