.navigation-container {
  height: 6rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
  background: lightgrey;

  .nav-logo-container {
    padding: 10px;

    .nav-logo {
      width: 60px;
      height: 60px;
      margin-bottom: 20px;
    }
  }

  .nav-links-container {
    width: 50%;

    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 10px;

    .nav-link-container {
      display: flex;
      background: #4b0082;
      padding: 10px;
      border-radius: 5px;
      margin: 5px;

      .nav-link {
        text-decoration: none;
        font-weight: 400;
        font-size: 15px;
        color: white;
        cursor: pointer;
      }
    }
  }
}

// #4b0082;
