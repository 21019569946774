.all-events-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin: 0.5em;
  column-gap: 1em;
  row-gap: 1em;
  padding: 0.5rem;

  .home-error-message {
    font-size: 20px;
    font-family: OpenSans-Regular;
    font-weight: 500;
    color: #4b0082;
    text-align: center;
  }
}

@media only screen and (max-width: 1500px) {
  .all-events-container {
    grid-template-columns: repeat(3, 1fr);
  }
}
