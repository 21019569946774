.card-container {
  box-sizing: border-box;
  padding: 1em;
  width: 100%;
  height: 30rem;
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: linear-gradient(
    to bottom,
    #964ccc77,
    #3d207399,
    #3d2073fa,
    #3d2073ff
  );
  .event-img {
    width: auto;
    height: 40%;
    transition: all 0.5s ease;
    border-radius: 15px;
  }

  .title {
    color: rgb(220, 220, 220);
    height: 12%;
    width: 100%;
    text-align: center;
    font-size: 1.5rem;
  }
  .paragraph {
    position: relative;
    width: 100%;
    max-height: 17%;
    padding-bottom: 25px;
    color: rgb(220, 220, 220);
    font-size: 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  .read-more {
    color: #0066cc;
    cursor: pointer;
    text-decoration: underline;
    display: inline;
    margin-left: 5px;
  }

  .expanded {
    -webkit-line-clamp: none;
    max-height: none;
    overflow-y: scroll;
  }

  .button-box {
    width: 100%;
    height: 12%;
  }
}

@media only screen and (max-width: 1150px) {
  .card-container {
    box-sizing: border-box;
    width: 100%;
    height: 95%;
    padding: 10px;

    .event-img {
      width: 80%;
    }

    .title {
      font-size: 1.3rem;
      text-align: center;
      height: 15%;
      width: 100%;
    }

    .paragraph {
      max-height: 20%;
      font-size: 1rem;
      text-align: center;
      padding-bottom: 0.25rem;
      padding-top: 0.25rem;
      // overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow-y: scroll;
      // max-height: isExpanded ? 'auto' : '20%';
      // overflow-y: isExpanded ? 'scroll' : 'hidden';
    }

    .read-more {
      color: #0066cc;
      cursor: pointer;
      text-decoration: underline;
      display: inline;
      margin-left: 5px;
    }

    .expanded {
      -webkit-line-clamp: none;
      max-height: none;
      overflow-y: scroll;
    }

    .button-box {
      width: 100%;
      height: 10%;
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
    }

    /* Modify the styles for the button */
    .btn-event-details {
      font-size: 0.85rem;
    }
  }
}

.btn-event-details {
  cursor: pointer;
  width: 100%;
  border-radius: 30px;
  padding: 0.8rem 1.5rem;
  font-weight: bold;
  color: white;
  border: none;
  font-size: 1rem;
  letter-spacing: 1px;
  background-color: #ffffff44;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
