.report-table-header {
  text-align: center;
  background: #36304a;
  font-family: OpenSans-Regular;
  color: #fff;
  height: 60px;

  th {
    // line-height: ;
    width: 260px;
  }
}

.report-table-data {
  cursor: pointer;
  background: white;
  color: gray;
  text-align: center;
  height: 45px;
  font-family: OpenSans-Regular;
  border-color: #212529;

  .column-data {
    width: 260px;
  }

  .sub-prom {
    color: #c850c0;
  }

  .staff {
    color: green;
  }

  .team {
    color: #212529;
    font-weight: bold;
  }
}
