body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  color: #212529;
}
// .download-report-container {
//   display: flex;
//   flex-direction: row;
//   // align-items: start;
//   align-self: flex-start;
//   background: green;
//   padding: 10px;

//   margin: 10px;

//   .download-report-link {
//     text-decoration: none;
//     font-weight: 400;
//     font-size: 15px;
//     color: white;
//     cursor: pointer;
//   }
// }
.download-report-container {
  display: flex;
  justify-content: center;
  margin: 20px 0;

  .download-report-link {
    background-color: #28a745;
    color: #ffffff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    display: flex;
    align-items: center; // vertically align icon with text
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #0056b3;
    }
  }
}

.event-reports-container {
  display: flex;
  flex-direction: column;
  margin: 1rem;
  align-items: center;

  .reports-error-message {
    font-size: 20px;
    font-family: OpenSans-Regular;
    font-weight: 500;
    color: #4b0082;
    text-align: center;
  }

  .report-logo-container {
    text-align: center;

    .report-logo {
      height: 150px;
      width: auto;
      padding: 0.5rem;
      margin: auto;
    }
  }

  .report-name-container {
    // background: yellow;

    .report-name {
      // background: magenta;
      font-family: OpenSans-Regular;
      text-align: center;
      color: #4b0082;
      font-size: 1.3rem;
      font-weight: 450;
    }
  }
}
